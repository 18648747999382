<template>
  <div>
    <v-dialog :value="dialog" width="471px" height="390px" persistent>
      <v-card id="card-modal" class="modal-card">
        <v-row>
          <v-col md="12" sm="12" xs="12" class="text-end pr-6">
            <v-btn icon @click="closeModal">
              <v-icon class="icon-close">mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col md="12" sm="12" xs="12" class="d-flex justify-content-center">
            <v-img
              src="icons/ic-farmer-alert.svg"
              width="116px"
              height="128px"
            />
          </v-col>
        </v-row>
        <v-card-text class="limit-exceeded pt-5">
          {{ $t('freemium.dialog.title') }}
        </v-card-text>
        <v-card-text class="pt-1 pb-2 info-exceeded">
          <span v-html="$t('freemium.dialog.description')"></span>
        </v-card-text>
        <v-card-actions>
          <v-btn
            style="padding: 20px 28px"
            color="success"
            block
            @click="openPaymentChoicesModal"
          >
            {{ $t('freemium.button.title') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <plan-payment-choices-modal
      v-if="isPaymentModalOpened"
      :plan-priority="planLevels.light"
      @close="closePaymentChoicesModal"
    />
  </div>
</template>

<script>
import planLevels from '@/modules/plans/data/planLevels'
import PlanMixin from '@/components/PlanMixin'
import PlanPaymentChoicesModal from '@/modules/payment/components/modal/PlanPaymentChoicesModal.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'DialogLimitFreemiumModal',

  mixins: [PlanMixin],

  components: {
    PlanPaymentChoicesModal,
  },
  props: {
    dialog: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      isPaymentModalOpened: false,
      planLevels,
    }
  },

  computed: {
    ...mapGetters('user', ['isBrazil']),
  },

  methods: {
    closeModal() {
      this.$emit('close')
    },
    closePaymentChoicesModal() {
      this.isPaymentModalOpened = false
    },
    openPaymentChoicesModal() {
      if (this.isBrazil) {
        this.isPaymentModalOpened = true
      } else {
        this.$router.replace({
          path: '/plans',
        })
      }
      this.closeModal()
    },
  },
}
</script>

<style scoped>
::v-deep .v-image__image.v-image__image--cover {
  background-size: contain !important;
}
::v-deep .v-card__actions {
  padding: 15px 25px 10px 0px;
}
::v-deep .v-dialog {
  overflow: hidden;
}

#card-modal .v-card__actions {
  padding: 20px 28px;
}

.icon-close {
  color: #aab2a9 !important;
  text-align: center;
  font-family: 'Font Awesome 5 Free';
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 9.5px;
  letter-spacing: 0.127px;
}

.modal-card {
  background-color: #fff;
  border-radius: 8px;
}

.image-card {
  padding: 24px 40px 12px 40px;
}

.limit-exceeded {
  font-family: 'Rubik';
  font-size: 20px;
  font-weight: bold;
  color: #1a2b46 !important;
  text-align: center;
}

.info-exceeded {
  font-family: 'Rubik';
  font-size: 16px;
  color: #5b6459;
  text-align: center;
}
</style>
