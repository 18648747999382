<template>
  <div class="catalog-info">
    <v-row align="center" align-content="space-between">
      <v-col>
        <df-info
          v-if="isPesticide"
          :icon="categoryDisplay.icon"
          :title="categoryDisplay.translate"
          :description="item.pesticide"
        />
        <df-info
          v-else-if="isVariety"
          :title="cropDisplay"
          :description="item.name"
        />
      </v-col>
      <v-col>
        <df-info
          icon="building"
          title="empresa"
          :description="item.asset_owner"
        />
      </v-col>
      <v-col v-if="isVariety && isSoybean">
        <df-info icon="chart-line" title="gmr" :description="getGmr(item)" />
      </v-col>
    </v-row>
    <v-card
      elevation="0"
      style="border: 1px solid #e6e9e6"
      class="rounded-lg"
      v-if="isPesticide"
    >
      <v-card-title class="card-title">
        {{ $t('Catalog.general_information') }}
      </v-card-title>
      <v-row class="px-10">
        <v-col cols="6">
          <df-info
            :title="$t('Catalog.chemical_group')"
            :description="getChemicalGroup(item)"
          />
        </v-col>
        <v-col cols="6">
          <df-info
            :title="$t('Catalog.action_mode')"
            :description="getActionMode(item)"
          />
        </v-col>
      </v-row>
      <v-row class="px-10">
        <v-col cols="6">
          <df-info
            :title="$t('Catalog.agronomic_category')"
            :description="$t(`Catalog.Options.${item.agronomic_category}`)"
          />
        </v-col>
        <v-col cols="6">
          <df-info
            :title="$t('Catalog.operation_type')"
            :description="getOperationType(item)"
          />
        </v-col>
      </v-row>
      <v-row class="px-10">
        <v-col cols="12">
          <df-info
            :title="$t('Catalog.active_ingredient_info')"
            :description="getActiveIngredient(item)"
          />
        </v-col>
      </v-row>
      <v-row class="px-10">
        <v-col cols="6">
          <df-info
            :title="$t('Catalog.measure')"
            :description="$t(`Catalog.Options.${item.pesticide_unit}`)"
          />
        </v-col>
        <v-col cols="6">
          <df-info
            :title="$t('Catalog.formulation')"
            :description="$t(`Catalog.Options.${item.formulation}`)"
          />
        </v-col>
      </v-row>
    </v-card>
    <v-card
      elevation="0"
      style="border: 1px solid #e6e9e6"
      class="rounded-lg my-3"
      v-if="isVariety"
    >
      <v-card-title class="card-title">
        {{ $t('Catalog.information_variety') }}
      </v-card-title>
      <v-row class="px-10">
        <v-col cols="4" v-if="!isWheat">
          <df-info
            v-if="item.general_info"
            :title="$t('Catalog.habit')"
            :description="
              $t(`Catalog.Options.${item.general_info.growth_habit}`)
            "
          />
        </v-col>
        <v-col cols="4">
          <df-info
            v-if="item.general_info"
            :title="$t('Catalog.approximate_cycle')"
            :description="getApproximateCycle(item)"
          />
        </v-col>
        <v-col cols="4" v-if="!isWheat">
          <df-info
            v-if="item.general_info"
            title="Tolerância à ferrugem"
            :description="getRustTolerant(item)"
          />
        </v-col>
      </v-row>
      <v-row class="px-10">
        <v-col cols="4">
          <df-info
            v-if="item.general_info"
            :title="$t('Catalog.suggested_population')"
            :description="getPopulation(item)"
          />
        </v-col>
        <v-col
          cols="4"
          v-if="
            item.general_info?.start_sowing_format !== 'undefined' && !isWheat
          "
        >
          <df-info
            v-if="item.general_info"
            :title="$t('Catalog.sowing_time')"
            :description="getSowingTime(item)"
          />
        </v-col>
        <v-col cols="4" v-if="!isWheat">
          <df-info
            :title="$t('Catalog.ogm')"
            :description="$t(item.general_info?.ogm.toLowerCase())"
          />
        </v-col>
      </v-row>
    </v-card>
    <v-card
      elevation="0"
      style="border: 1px solid #e6e9e6"
      class="rounded-lg my-3"
      v-if="isFungicide"
    >
      <v-card-title class="card-title">
        {{ $t(`Catalog.dosis_target`) }}
      </v-card-title>
      <v-row>
        <v-col>
          <v-list>
            <v-list-item-group
              v-for="(operation, key) in getOperations"
              :key="key"
            >
              <v-list-item class="d-flex">
                <v-list-item-content
                  class="py-2 list-item-flex list-item-target"
                >
                  {{ $t(`Catalog.Options.${operation.target}`) }}
                </v-list-item-content>
                <v-divider
                  class="dotted-line align-self-center flex-grow-2 mx-4"
                ></v-divider>
                <v-list-item-content
                  class="py-2 list-item-flex list-item-target-value"
                >
                  {{ getValueDosisByTarget(operation) }}
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DfInfo from '@/lib/components/Info/DfInfo.vue'

export default {
  name: 'CatalogInfo',

  components: {
    DfInfo,
  },

  props: {
    item: { type: Object, required: true },
  },

  computed: {
    ...mapGetters('catalog', ['catalogCategory']),
    ...mapGetters('crops', ['isSoybean', 'isWheat']),
    cropDisplay() {
      if (this.isSoybean) return this.$t(`Catalog.FilterBar.Crop.soybean`)
      if (this.isWheat) return this.$t(`Catalog.FilterBar.Crop.wheat`)
      return null
    },
    categoryDisplay() {
      switch (this.catalogCategory) {
        case 'fungicide':
          return {
            icon: 'vial',
            translate: this.$t('Catalog.Categories.fungicide'),
          }
        case 'nematicide':
          return {
            icon: 'bacterium',
            translate: this.$t('Catalog.Categories.nematicide'),
          }
        default:
          return {
            icon: null,
            translate: null,
          }
      }
    },
    isVariety() {
      return this.catalogCategory === 'variety'
    },
    isPesticide() {
      const pesticideTypes = ['fungicide', 'nematicide']
      return pesticideTypes.includes(this.catalogCategory)
    },
    isFungicide() {
      return this.catalogCategory === 'fungicide'
    },
    getOperations() {
      const operations = this.item?.operations?.[0]
      return Array.isArray(operations)
        ? operations.map((operation) => ({ ...operation }))
        : []
    },
  },

  methods: {
    getChemicalGroup(item) {
      const ingredients = item?.activeIngredients
      if (!Array.isArray(ingredients) || ingredients.length === 0) {
        return ''
      }
      const chemicalGroups = ingredients.map((ingredient) =>
        this.$t(`Catalog.Options.${ingredient.chemical_group}`)
      )
      return chemicalGroups.join('<br>')
    },
    getActionMode(item) {
      const ingredients = item?.activeIngredients
      if (!Array.isArray(ingredients) || ingredients.length === 0) {
        return ''
      }
      const uniqueActionMode = [
        ...new Set(
          ingredients.map((ingredient) =>
            this.$t(`Catalog.Options.${ingredient.mode_of_action}`)
          )
        ),
      ]
      return uniqueActionMode.join('<br>')
    },
    getOperationType(item) {
      const operations = item?.operations
      if (!Array.isArray(operations) || operations.length === 0) {
        return ''
      }
      const uniqueOperationType = [
        ...new Set(
          operations
            .flat()
            .map((operation) =>
              this.$t(`Catalog.Options.${operation.operation_type}`)
            )
        ),
      ]
      return uniqueOperationType.join('<br>')
    },
    getActiveIngredient(item) {
      const ingredients = item?.activeIngredients
      if (!Array.isArray(ingredients) || ingredients.length === 0) {
        return ''
      }
      const uniqueActionMode = [
        ...new Set(
          ingredients.map(
            (ingredient) =>
              `${this.$t(`Catalog.Options.${ingredient.active_ingredient}`)} (${
                ingredient.active_ingredient_concentration
              } ${this.$t(
                `Catalog.Options.${ingredient.active_ingredient_unit}`
              )})`
          )
        ),
      ]

      return uniqueActionMode.join('<br>')
    },
    getApproximateCycle(item) {
      return `${item.general_info.min_cycle} a ${
        item.general_info.max_cycle
      } ${this.$t('days')}`
    },
    getRustTolerant(item) {
      return item.general_info.rust_tolerant ? this.$t('yes') : this.$t('no')
    },
    getPopulation(item) {
      return `
        ${item.general_info.min_population} 
        ${this.$t('Catalog.to')} 
        ${item.general_info.max_population}
        ${this.$t('Catalog.population_suggestion')}
      `
    },
    getSowingTime(item) {
      return `${item.general_info.start_sowing_format} à ${item.general_info.end_sowing_format}`
    },
    getGmr(item) {
      return `${this.$t('Catalog.gmr')}: ${
        item.general_info?.relative_maturity || this.$t('desconhecido')
      }`
    },
    getValueDosisByTarget(operation) {
      return this.$t('Catalog.dosis_by_target_values', [
        operation.dosis_min,
        operation.dosis_max,
      ])
    },
  },
}
</script>

<style scoped>
.catalog-info {
  height: 100%;
  background: #fff;
  text-align: center;
  color: #1a2b46;
  font-family: 'Rubik';
  font-weight: 500;
  font-size: 16px;
}
.card-title {
  color: #aab2a9;
  font-family: 'Rubik';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
}
.dotted-line {
  width: 100%;
  border: 1px solid #e6e9e6;
}
.list-item-flex {
  flex: 0 1 auto !important;
}
.list-item-target {
  color: #5b6459;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.list-item-target-value {
  color: #aab2a9;
  font-family: 'Rubik';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
</style>
